<template>
  <div class="mt-2">
    <h3>{{ esu.esuName }}</h3>
  </div>
  <div class="accordion mt-2">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed"
              :id="'esu-admins-header' + idEsu"
              aria-expanded="false"
              :aria-controls="'esu-admins-' + idEsu"
              data-toggle="collapse"
              :data-target="'#esu-admins-' + idEsu">ESU Admins
      </button>
    </h2>
    <div :id="'esu-admins-' + idEsu"
         class="accordion-collapse collapse"
         :aria-labelledby="'esu-admins-header' + idEsu">
      <UserHierarchyList :role-hierarchy="2" :id-organization="idEsu" :org-type="1"/>
    </div>
  </div>
</template>

<script>
import {ref, onBeforeMount, watch} from "vue";
import axios from "axios";
import {API_URL} from "../../../../Constants";
import UserHierarchyList from "@/components/UserOrganizationHierarchy/UserHierarchyList";


export default {
  name: "EsuHierarchy",
  components: {
    UserHierarchyList,
  },
  props: {
    idEsu: Number
  },
  setup(props, context) {
    const esu = ref({});
    const selectedDistrict = ref(-1);

    onBeforeMount(() => {
      getEsu();
    })

    watch(() => props.idEsu, () => {
      getEsu();
    })

    async function getEsu() {
      let get_uri = API_URL + "/esu/byId?_idEsu=" + props.idEsu;
      await axios.get(get_uri)
          .then((result) => {
            esu.value = result.data;
          })
    }

    function updateDistrict(event) {
      selectedDistrict.value = event;
    }

    return {
      esu,
      selectedDistrict,
      updateDistrict
    }

  }
}
</script>

<style scoped>
.accordion {
  margin: 10px;
}

.accordion-header {
  border: 1px solid black;
}
</style>