<template>
  <div class="mt-2">
    <h3>
      {{ district.districtName }}
    </h3>
  </div>
  <div class="accordion mt-2">
    <h2 class="accordion-header">
      <button
          class="accordion-button collapsed"
          :id="'district-admins-header' + idDistrict"
          aria-expanded="false"
          :aria-controls="'district-admins-' + idDistrict"
          data-toggle="collapse"
          :data-target="'#district-admins-' + idDistrict"
      >
        District Admins
      </button>
    </h2>
    <div :id="'district-admins-' + idDistrict" class="accordion-collapse collapse"
         :aria-labelledby="'district-admins-header' + idDistrict">
      <UserHierarchyList
          :role-hierarchy="3"
          :id-organization="idDistrict"
          :org-type="2"/>
    </div>
  </div>
</template>

<script>
import {onBeforeMount, ref, watch} from "vue";
import axios from "axios";
import {API_URL} from "../../../../Constants";
import UserHierarchyList from "@/components/UserOrganizationHierarchy/UserHierarchyList";
import {getDistrict} from "@/Helpers/ApiCalls/DistrictAPICalls";

export default {
  name: "DistrictHierarchy",
  components: {UserHierarchyList},
  props: {
    idDistrict: Number
  },
  setup(props) {
    const district = ref({});
    const selectedSchool = ref(-1);

    onBeforeMount(() => {
      getDistrictCall();
    })

    watch(() => props.idDistrict, () => {
      getDistrictCall();
    })

    async function getDistrictCall() {
      await axios.get(getDistrict(props.idDistrict),{
        params: {
          params: {
            showUserCount: false
          }
        }
      }).then((result) => {
            district.value = result.data;
          })
    }

    function updateSelectedSchool(event) {
      selectedSchool.value = parseInt(event);
    }

    return {
      district,
      selectedSchool,
      updateSelectedSchool
    }
  }
}
</script>

<style scoped>
.accordion {
  margin: 10px;
}

.accordion-header {
  border: 1px black solid;
}
</style>