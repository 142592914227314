<template>
  <div v-if="showNebraskaHierarchy">
    <div class="row">
      <div class="col-sm">
        <div v-if="selectedEsu !== -1">
          <EsuHierarchy
              :id-esu="selectedEsu"
          />
        </div>
      </div>
      <div class="col-sm">
        <div v-if="selectedDistrict !== -1">
          <DistrictHierarchy
              :id-district="selectedDistrict"/>
        </div>
        <!--        <div v-else>-->
        <!--          <div v-for="districtId in districtIdArray" :key="districtId">-->
        <!--            <DistrictHierarchy :id-district="districtId"/>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div class="col-sm">
        <div v-if="selectedSchool !== -1">
          <SchoolHierarchy
              :id-school="selectedSchool"
          />
        </div>
        <div v-else>
          <div v-for="schoolId in schoolIdArray" :key="schoolId">
            <SchoolHierarchy
                :id-school="schoolId"/>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div v-else-if="selectedOutStateOrg !== -1">
    <div class="col-sm"></div>
    <OutstateHierarchy
        :id-organization="selectedOutStateOrg"
    />
  </div>

</template>

<script>
import {computed, onBeforeMount, ref, watch} from "vue";
import OutstateHierarchy from "@/components/UserOrganizationHierarchy/OutstateHierarchy/OutstateHierarchy";
import SchoolHierarchy from "@/components/UserOrganizationHierarchy/SchoolHierarchy/SchoolHierarchy";
import DistrictHierarchy from "@/components/UserOrganizationHierarchy/DistrictHierarchy/DistrictHierarchy";
import EsuHierarchy from "@/components/UserOrganizationHierarchy/EsuHierarchy/EsuHierarchy";

export default {
  name: "OrganizationHierarchy",
  components: {
    OutstateHierarchy,
    SchoolHierarchy,
    EsuHierarchy,
    DistrictHierarchy,

  },
  props: {
    countryCode: String,
    stateCode: String,
    idEsu: Number,
    idDistrict: Number,
    idSchool: Number,
    idOutstateOrg: Number,
    schoolIdArray: Array,
    districtIdArray: Array,
  },
  setup(props) {
    const selectedEsu = ref(-1);
    const selectedDistrict = ref(-1);
    const selectedSchool = ref(-1);
    const selectedState = ref("-1");
    const selectedCountry = ref("-1");
    const selectedOutStateOrg = ref(-1);

    onBeforeMount(() => {
      if (props.countryCode) selectedCountry.value = props.countryCode;
      if (props.stateCode) selectedState.value = props.stateCode;
      if (props.idOutstateOrg) selectedOutStateOrg.value = props.idOutstateOrg;
      if (props.idEsu) selectedEsu.value = props.idEsu;
      if (props.idDistrict) selectedDistrict.value = props.idDistrict;
      if (props.idSchool) selectedSchool.value = props.idSchool;
    })

    watch(() => props.countryCode, () => {
      selectedCountry.value = props.countryCode;
    })

    watch(() => props.stateCode, () => {
      selectedState.value = props.stateCode;
    })

    watch(() => props.idEsu, () => {
      selectedEsu.value = props.idEsu;
    })

    watch(() => props.idDistrict, () => {
      selectedDistrict.value = props.idDistrict;
    })

    watch(() => props.idSchool, () => {
      selectedSchool.value = props.idSchool;
    })

    watch(() => props.idOutstateOrg, () => {
      selectedOutStateOrg.value = props.idOutstateOrg;
    })

    const showNebraskaHierarchy = computed(() => {
      return selectedState.value === 'NE';
    })

    return {
      selectedEsu,
      selectedDistrict,
      selectedSchool,
      selectedState,
      selectedCountry,
      selectedOutStateOrg,
      showNebraskaHierarchy,
    }
  }
}
</script>

<style scoped>

</style>