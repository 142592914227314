<template>

<!--  <div class="mt-2">-->
    <h3>{{ school.schoolName }}</h3>
<!--  </div>-->
  <div class="row">
    <div class="">
      <div class="accordion mt-2">

        <h2 class="accordion-header">
          <button class="accordion-button collapsed"
                  :id="'instructors-header' + idSchool"
                  aria-expanded="false"
                  :aria-controls="'instructors-' + idSchool"
                  data-toggle="collapse"
                  :data-target="'#instructors-' + idSchool">Supervisor
          </button>
        </h2>
        <div :id="'instructors-' + idSchool" class="accordion-collapse collapse"
             :aria-labelledby="'instructors-header' + idSchool">
          <UserHierarchyList
              :org-type="3"
              :id-organization="idSchool"
              :role-hierarchy="4"
          />
        </div>
      </div>
    </div>
    <div class="">

      <div class="accordion mt-2 ">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed"
                  :id="'paras-header' + idSchool"
                  aria-expanded="false"
                  :aria-controls="'paras-' + idSchool"
                  data-toggle="collapse"
                  :data-target="'#paras-' + idSchool">Students (Para Course)
          </button>
        </h2>
        <div :id="'paras-' + idSchool" class="accordion-collapse collapse"
             :aria-labelledby="'paras-header' + idSchool"
        >
          <UserHierarchyList
              :org-type="3"
              :id-organization="idSchool"
              :role-hierarchy="5"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onBeforeMount, ref, watch} from "vue";
import {API_URL} from "../../../../Constants";
import axios from "axios";
import UserHierarchyList from "@/components/UserOrganizationHierarchy/UserHierarchyList";
import {getSchool} from "@/Helpers/ApiCalls/SchoolAPICalls";

export default {
  name: "SchoolHierarchy",
  components: {UserHierarchyList},
  props: {
    idSchool: Number
  },
  setup(props) {
    const selectedSchoolId = ref(-1);
    const school = ref({});

    onBeforeMount(() => {
      selectedSchoolId.value = props.idSchool;
      getSchoolCall();
    })

    watch(() => props.idSchool, () => {
      selectedSchoolId.value = props.idSchool;
      getSchoolCall();
    })

    async function getSchoolCall() {
      await axios.get(getSchool(props.idSchool), {
        params: {
          showUserCount: false
        }
      }).then((result) => {
            school.value = result.data;
          })
    }

    return {
      selectedSchoolId,
      school
    }
  }
}
</script>

<style scoped>
.accordion {
  margin: 10px;
}

.accordion-header {
  border: 1px black solid;
}
</style>